import { FsMatch } from "db/model/match/FsMatch";
import { FsMatchTeam } from "db/model/match/FsMatchTeam";
import { matchSetHasData } from "./Set/MatchSet";
import { Match } from "model/IdData";

export const getTeams = (match: FsMatch) => {
  return match.teams?.filter((t) => typeof t === "object") as FsMatchTeam[];
};

export const getNumTeams = (match: FsMatch) => {
  return (
    match.teams?.filter((t) => typeof t === "object" || t === "TBD").length ?? 0
  );
};

export const matchHasData = (match: FsMatch) => {
  if (!!Object.keys(match.scoreOverrides ?? {}).length) {
    return true;
  }

  return match.sets?.some(matchSetHasData) ?? false;
};

export const matchCompleteByByesOrForfeits = (match: FsMatch) => {
  // If 0 or 1 teams, not configured properly, therefore not really complete
  if (!match.teams || match.teams.length === 1) {
    return false;
  }

  const numNonByeTeams = match.teams.filter((t) => t !== "BYE").length;
  if (numNonByeTeams === 1) {
    return true;
  }

  const numForfeits = getTeams(match).filter((t) => t.forfeited).length;
  return numForfeits >= numNonByeTeams - 1;
};

export const splitMatchesByDay = (matches: Match[]) => {
  const matchesByDate = new Map<
    { day: number; monthIdx: number; year: number },
    Match[]
  >();
  const matchesWithUnknownDate: Match[] = [];
  matches.forEach((m) => {
    if (!!m.data.startTimeMs) {
      const date = new Date(m.data.startTimeMs);
      const day = date.getDate();
      const monthIdx = date.getMonth();
      const year = date.getFullYear();

      const key = Array.from(matchesByDate.keys()).find(
        (k) => k.day === day && k.monthIdx === monthIdx && k.year === year
      );
      if (!!key) {
        matchesByDate.get(key)!.push(m);
      } else {
        matchesByDate.set({ day, monthIdx, year }, [m]);
      }
    } else {
      matchesWithUnknownDate.push(m);
    }
  });

  return { matchesByDate, matchesWithUnknownDate };
};
